import React from 'react';
import ProfileVisibility from '../../Molecules/Accounts/PrivacySettings/ProfileVisibility';

const PrivacySettings = () => {
    return (
        <div>
            <ProfileVisibility />
        </div>
    );
};

export default PrivacySettings;