import React from "react";

export default function AboutUs() {
  return (
    <>
      <div className="bg-[#f4f4f4]">
        <div className="container py-8">
          <h1 className="ml-2 mt-6 mb-5 text-[52px] text-[#051532]">
            About Us
          </h1>
          <p className="ml-3 text-lg mb-4 text-[#4f4f4f]">
            Beyond the Job Search: Unraveling the Story of JobMq, Our Culture,
            Commitment, and Endless Pursuit of Excellence
          </p>
        </div>
      </div>

      <div className="container pt-16 px-3 pb-16">
        <p className="text-base mb-4 text-[#4f4f4f]">
          JOBMQ is emerging icon to attract the JOB seekers and JOB publisher,
          It's a promising platform loaded with many ideas based on trending
          technology and algorithms which will be coming in new releases of
          JOBMQ platform.
        </p>
        <p className="text-base mb-4 text-[#4f4f4f]">
          JOBMQ is a free platform that provides the basic need of JOB seekers,
          to find and apply for a Job position. JOBMQ does not charge any fee
          from JOB seekers or candidates to register or apply for a JOB.
          Similarly, JOBMQ does not charge any fee from any business to publish
          job positions on the JOBMQ platform.
        </p>
        <p className="text-base mb-4 text-[#4f4f4f]">
          It's a free platform that is designed to self-sustain with minimum
          human interference that provides a lifecycle process from job
          publishing to hire and from Job search to apply.
        </p>
        <p className="text-base mb-4 text-[#4f4f4f]">
          JOBMQ is not a bug-free platform or final product, it's continuing to
          evolve new features, technology, and collaboration by bringing new
          bridges for plug and play.
        </p>
        <p className="text-base mb-4 text-[#4f4f4f]">
          JOBMQ collects candidate data and business data only to match
          candidate profiles with job positions.
        </p>
        <p className="text-base mb-4 text-[#4f4f4f]">
          Businesses can review the candidate profile who applies for the JOB
          and similarly, candidates can also review the profile of business and
          job details published by business.
        </p>
        <p className="text-base mb-4 text-[#4f4f4f]">
          Accuracy of data lies on the honesty and ethics of the candidates or
          businesses who provide information. JOBMQ is not responsible for any
          misleading candidate or business or job information. We do validate
          the existence of the business but we do not validate jobs published by
          the business. In the case of candidates, we do validate their email
          addresses but we do not validate the information that they provide in
          their candidate profile.
        </p>
      </div>
    </>
  );
}
