import React, { useState } from 'react';
import { FiAlertCircle } from "react-icons/fi"
import { NavLink, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Loading from '../../Atoms/Alert/Loading';
import GLogin from '../../Molecules/Register/GLogin';
import Linkedin from '../../Molecules/Register/Linkedin';
import { useLoginMutation } from '../../../../features/auth/authApi';
import TextField from '../../Atoms/Inputs/TextField';
import { Helmet } from 'react-helmet-async';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';
// import FbLogin from '../../Molecules/Register/FbLogin';

const LoginFrom = () => {
    const navigate = useNavigate()
    const [login, { data, isLoading, isSuccess }] = useLoginMutation()
    const [externalLoginSuccess, setExternalLoginSuccess] = useState(false)
    const [ showPassword,setShowPassword] = useState(false);
    const initialValues = {
        userName: '',
        password: ""
    }
    const validationSchema = yup.object({
        userName: yup.string().email("Enter a valid email address").required("email is required"),
        password: yup.string("Enter your password").required("password is required").min(6, "Must have atleast 6 characters")
    })
    const onSubmit = values => {
        login(values)
    }

    if (data?.isSuccess && isSuccess) {
        setTimeout(() => {
            if (data?.token?.userType === 1) {
                navigate("/individuals/profile");
            }
            if (data?.token?.userType === 2) {
                navigate("/businesses/profile");
            }

        }, 100);
    }

    return (
        <>
            <Helmet>
                <title>Login | JOBMQ</title>
            </Helmet>
            <div className='bg-site-bg-100 py-10'>
                <div className=' bg-white w-full shadow-lg rounded-md max-w-[1137px] mx-auto px-2 sm:px-10 py-10'>
                    {(isLoading || externalLoginSuccess) && <Loading />}
                    <div className="w-[873px] mx-auto">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            <Form className='flex flex-col gap-3'>
                                <h1 className='font-roboto text-[50px] font-light leading-[75px] text-site-bg-300'>Sign In</h1>
                                <TextField name={'userName'} label={'Email'} type={'email'} placeholder={'Email'} />
                                <div className="flex justify-center items-center gap-3">
                                    <div className='w-full'>
                                        <TextField name={'password'} label={'Password'} type={ showPassword ? 'text' :'password'} placeholder={'Password'} />
                                    </div>
                                    { !showPassword ? <button type='button'><FaRegEye className="text-site-bg-300 font-semibold" size="25" onClick={()=>setShowPassword(!showPassword)}/></button> : <button type='button'><FaEyeSlash className="text-site-bg-300 font-semibold" size="25" onClick={()=>setShowPassword(!showPassword)}/></button> }   
                                </div>
                                {/* <TextField name={'password'} label={'Password'} type={'password'} placeholder={'Password'} /> */}


                                <button type='submit' className="w-full bg-site-bg-300 text-white rounded h-18 text-xl-22">Sign in</button>
                                <span className='text-lg font-roboto font-normal text-site-text-200 flex items-center gap-2'>
                                    <FiAlertCircle className="text-site-bg-300 font-semibold" />
                                    <span className="text-site-text-300">Don't have an account? </span>
                                    <NavLink
                                        className="font-semibold text-site-text-200 underline"
                                        to={'/individuals/create-account'}>
                                        Create a JOBMQ account
                                    </NavLink>
                                </span>
                            </Form>
                        </Formik>


                        <div className="flex items-center justify-center gap-2.5 mt-10">
                            {/* <FbLogin /> */}
                            <GLogin />
                            <Linkedin setExternalLoginSuccess={setExternalLoginSuccess} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginFrom;