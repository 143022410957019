import React from "react";

export default function ContactUs() {
  return (
    <>
      <div className="bg-[#f4f4f4] text-center">
        <div className="container py-8">
          <h1 className="ml-2 mt-6 mb-5 text-[52px] text-[#051532]">
            Contact Us
          </h1>
          <p className="ml-3 text-lg mb-4 text-[#4f4f4f]">
            Connect with Us - Let's Find Your Dream Job Together!
          </p>
        </div>
      </div>

      <div className="container pt-16 px-3 pb-16 text-center">
        <p className="text-base mb-4 text-[#4f4f4f]">
          For any suggestion or enquiry kindly contact us at  &nbsp;<a className="link-primary underline text-[#4c6dff]" href="mailto:contact@jobmq.com">contact@jobmq.com</a>
        </p>
      </div>
    </>
  );
}
