
const server = 'pro'

const serverConfiguration = {
    'local' : {
        baseUrl :'http://localhost:3001/', 
        jobSSR : 'http://localhost:3000/',
        serverURL :'https://api.jobmq.com/api',
        serverFileURL : 'https://api.jobmq.com/'
    },
    'custom' :{
        baseUrl :'https://jobmqmain.netlify.app/', 
        jobSSR : 'https://jobmqjobs.vercel.app/',
        serverURL :'https://api.jobmq.com/api',
        serverFileURL : 'https://api.jobmq.com/'
    },
    'staging' : {
        baseUrl :'https://stg.jobmq.com/', 
        jobSSR : 'https://stg.jobs.jobmq.com/',
        serverURL :'https://stg.api.jobmq.com/api',
        serverFileURL : 'https://stg.api.jobmq.com/'
    },
    'validation' : {
        baseUrl :'https://validation.jobmq.com/', 
        jobSSR : 'https://validation.jobs.jobmq.com/',
        serverURL :'https://validation.api.jobmq.com/api',
        serverFileURL : 'https://validation.api.jobmq.com/'
        
    },
    'pro' : {
        baseUrl :'https://jobmq.com/', 
        jobSSR : 'https://jobs.jobmq.com/',
        serverURL :'https://api.jobmq.com/api',
        serverFileURL : 'https://api.jobmq.com/'
    }
}

const socialURLS = {
    facebook : "https://www.facebook.com/JOBMQ-921344251566840/",
    twitter : "https://twitter.com/JOBMQ1/",
    linkedin : "https://www.linkedin.com/company/jobmq/",
    instagram : "https://www.instagram.com/jobmq/", 
}


module.exports.serverConfiguration = serverConfiguration[server];
module.exports.socialURLS = socialURLS;
