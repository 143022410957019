/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useJobListingMutation } from "../../../../features/job/jobListingApi";
import moment from "moment/moment";

import Filter from "./Filter";
import Loading from "../../../Components/Atoms/Alert/Loading";
import Pagination from "./Pagination";
import { useSelector } from "react-redux";
import SearchJob from "./SearchJob";
// import { NavLink } from "react-router-dom";
import SortBy from "./SortBy";
import Dropdowns from "./Dropdowns";
import { serverConfiguration } from "../../../../config/index.constant";
import { Helmet } from "react-helmet";

export default function JobList() {
  const [jobListing, { data, isLoading, isError, error }] =
    useJobListingMutation();
  const jobListData = useSelector((state) => state.jobList);
  const userAuthData = JSON.parse(localStorage.getItem('auth')) 
  const accessToken = userAuthData ? userAuthData.accessToken : '';
  useEffect(() => {
    jobListing({ ...jobListData });
  }, []);

  let content = null;
  if (isLoading) {
    content = <Loading />;
  }
  if (!isLoading && isError) {
    content = <p>{error}</p>;
  }
  if (!isLoading && !isError && data?.jobsListingResponses?.length === 0) {
    content = <p>No Found data</p>;
  }

  if (!isLoading && !isError && data?.jobsListingResponses?.length > 0) {
    content = data?.jobsListingResponses?.map((item) => (
      <Single key={item.id} {...item} accessToken={accessToken} />
    ));
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title data-react-helmet="true">Online Job Portal | JOBMQ</title>
        <meta name="description" 
          content="JOBMQ: A new era for millions of people to extend their reach globally. Find your next job opportunity or post a job listing."
          data-react-helmet="true"
        />
      </Helmet>
      <div className="bg-[#f4f4f4] pt-16 pb-24">
        <div className="container">
          <h2 className="text-[#051532] text-[52px] mt-6 mb-5">Online job portal, Upload your CV or Post a JOB.</h2>
          <p className="text-[#4f4f4f] text-lg">
            JOBMQ a new era for Millions of people to extend their reach globally.
          </p>
        </div>
      </div>
      <div className="-mt-14">
        <SearchJob jobListing={jobListing} />
      </div>
      <div className="container grid grid-cols-3 gap-3 my-3">
        <Filter jobListing={jobListing} />
        <div className="col-span-3 sm:col-span-2">
          <SortBy jobListing={jobListing} />
          <div className="space-y-3 mt-1">{content}</div>
        </div>
      </div>
      <Pagination {...data} jobListing={jobListing} />
    </div>
  );
}

const Single = (props) => {
  const {
    id,
    description,
    maxSalary,
    minSalary,
    desingnation,
    expirationDate,
    currency,
    slugUrl,
    accessToken, 
    seoTitle,
    seoDescription
  } = props || {};
  
  const designationTitle = slugUrl?.replace(/[^a-zA-Z0-9_]+/g, '_') || desingnation?.replace(/[^a-zA-Z0-9_]+/g, '_');
  const encodedToken = accessToken ? encodeURIComponent(accessToken) : '';
  const ssrJobURl = accessToken ? `${serverConfiguration.jobSSR}jobs/${id}/${designationTitle}/token/?q=${encodedToken}` : `${serverConfiguration.jobSSR}jobs/${id}/${designationTitle}/left_access`;
  const jobDescription = description.slice(0,350);

  return (
    <div className="p-5 bg-white border-b">
      <Helmet>
        {/* <title data-react-helmet="true">{designationTitle}</title> */}
        <title data-react-helmet="true">Online Job Portal | JOBMQ</title>
        <link rel="job detail page" href={ssrJobURl} data-react-helmet="true"/>
        <meta name="description" content={seoDescription} data-react-helmet="true"/>
        <meta property="og:seoTitle" content={seoTitle || 'JOBMQ'} data-react-helmet="true"/>
      </Helmet>
      <div className="flex justify-between">
        <a
          // to={`/jobs/${id}/${slugUrl || desingnation?.split(" ").join('-')}`} 
          href={ssrJobURl} 
          className="text-lg underline hover:no-underline text-[#0076bd]">{desingnation}</a>
        <div className="flex gap-3 items-center">
          
          <Dropdowns url={`${serverConfiguration.jobSSR}jobs/${id}`} />
        </div>
      </div>
      <div className="pt-2 space-y-2">
        <p className=" text-sm text-[#4f4f4f]">
          <span className="font-semibold">Expiring on:</span>{" "}
          {moment(expirationDate).format("DD MMM  YYYY")}{" "}
        </p>
        <p className="text-sm text-[#4f4f4f] pb-2">
          {currency ? currency : "$"} {minSalary} – {maxSalary}{" "}

        </p>
        <p className="text-sm pb-2 text-[#051532] ml-3 innnerHTML" dangerouslySetInnerHTML={{__html : jobDescription + `... <a href="${ssrJobURl}" style="color:#0076bd;text-decoration:underline;">read more</a>`}}></p>
        {/* <p className="text-sm pb-4 text-[#051532]">{description.slice(0, 350)}</p> */}
      </div>

    </div>
  );
};
