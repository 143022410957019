import React from 'react';
import Businesses from '../../../Components/Organisms/Profile/Businesses';

const Profile = () => {
    return (
        <>
            <Businesses />
        </>
    );
};

export default Profile;