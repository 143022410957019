import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../Alert/TextError'
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill';


export default function RichTextBox(props) {

    const { label, name, left, blur, formik } = props;

    // var modules = {
    //     toolbar: [
    //         [{ size: ["small", false, "large", "huge"] }],
    //         ["bold", "italic", "underline", "strike", "blockquote"],
    //         [{ list: "ordered" }, { list: "bullet" }],
    //         ["link", "image"],
    //         [
    //             { list: "ordered" },
    //             { list: "bullet" },
    //             { indent: "-1" },
    //             { indent: "+1" },
    //             { align: [] }
    //         ],
    //         [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    //     ]
    // };

    // var formats = [
    //     "header", "height", "bold", "italic",
    //     "underline", "strike", "blockquote",
    //     "list", "color", "bullet", "indent",
    //     "link", "image", "align", "size",
    // ];


    return (
        <div className="">
            <div className="">
                <label htmlFor={name} className="block text-base font-bold text-gray-900">{label}</label>
                <Field id={name} name={name}>
                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} onBlur={(e)=>blur(field.value,'description',formik)}/>}
                </Field>
            </div>
            <div className="flex justify-between items-center px-2">
                <div className=""><ErrorMessage name={name} component={TextError} /> </div>
                <div className="">{left}</div>
            </div>
        </div>
    )
}
