import React from 'react';
import Individual from '../../../Components/Organisms/Profile/Individual';

const Profile = () => {
    return (
        <>
            <Individual />
        </>
    );
};

export default Profile;