import React from 'react';
import NotificationsOptions from '../../Molecules/Accounts/Notifications/NotificationsOptions';

const Notifications = () => {
  
    return (
        <>



            <NotificationsOptions />
        </>
    );
};

export default Notifications;