import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik'
import Cancle from '../../../Atoms/Buttons/Cancle';
import { useCreatePasswordMutation } from '../../../../../features/auth/registerApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../../../../features/auth/authApi';
import Loading from '../../../Atoms/Alert/Loading';
import FormikControl from '../../../Atoms/Forms/FormikControl';
import Back from '../../../Atoms/Buttons/Back';
import { FaRegEye,FaEyeSlash } from "react-icons/fa";

const CreatePassword = () => {
    const [pass, setPass] = useState(undefined)
    const [createpassword, { data: passwordData }] = useCreatePasswordMutation()
    const [login, { data, isLoading, isSuccess }] = useLoginMutation()
    const { email } = useSelector((state) => state.register)
    const auth = JSON.parse(localStorage.getItem('auth'))
    const navigate = useNavigate()
    const [ showPassword,setShowPassword] = useState(false);
    const [ showConfirmPassword,setShowConfirmPassword] = useState(false);


    const initialValues = {
        password: '',
        confirmPassword: '',
    }
    const validationSchema = yup.object({
        password: yup.string().required('Please Enter your password').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/,"Must Contain 7 Characters, One Uppercase, One Lowercase and One Number"),
        confirmPassword: yup.string("Enter your confirm password").required('Please Enter your confirm password.').oneOf([yup.ref('password')], 'Your passwords do not match.')
    })
    const onSubmit = values => {
        const { password, confirmPassword } = values
        if (password === confirmPassword ) {
            setPass(password)
            createpassword({
                email: auth.email || email,
                password
            })

        }
    }

    useEffect(() => {
        if (passwordData?.isSuccess) {
            login({
                userName: auth.email || email,
                password: pass
            })
        }

        return () => { }
    }, [auth.email, email, login, pass, passwordData])

    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/individuals/profile");
            }, 100);
        }

    }, [data?.isSuccess, isSuccess, navigate])


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >

            {
                formik => (
                    <Form>
                        <div className='text-lg w-full max-w-[650px] mx-auto sm:px-10 py-10'>
                            {isLoading && <Loading />}
                            <Back />
                            <h2 className='text-[32px] font-bold'>Create password</h2>
                            <div className="flex flex-col gap-4 pt-5">
                                <p>Step 4 of 4</p>
                                {/* <p>Your password must have at least 7 characters and includes at least 1 number</p>
                                <p>Do not show you password</p> */}

                            </div>

                            <div className="sp space-y-4 py-5">
                                <div className="flex justify-center items-center gap-3">
                                    <div className='w-full'>
                                        <FormikControl control="input" type={ !showPassword ? "password" : 'text' } label="Password" name="password" />
                                    </div>
                                    <div>
                                        { !showPassword ? <button type='button'><FaRegEye className="text-site-bg-300 font-semibold" size="25" onClick={()=>setShowPassword(!showPassword)}/></button> : <button type='button'><FaEyeSlash className="text-site-bg-300 font-semibold" size="25" onClick={()=>setShowPassword(!showPassword)}/></button> }   
                                    </div>
                                </div>
                                <div className="flex justify-center items-center gap-3">
                                    <div className='w-full'>
                                        <FormikControl control="input" type={ !showConfirmPassword ? "password" : 'text' } label="Confirm Password" name="confirmPassword" />
                                    </div>
                                    <div>
                                        { !showConfirmPassword ? <button type='button'><FaRegEye className="text-site-bg-300 font-semibold" size="25" onClick={()=>setShowConfirmPassword(!showConfirmPassword)}/></button> : <button type='button'><FaEyeSlash className="text-site-bg-300 font-semibold" size="25" onClick={()=>setShowConfirmPassword(!showConfirmPassword)}/></button> }   
                                    </div>
                                </div>
                            </div>

                            <div className=" flex gap-3 items-center">
                                <button type='submit' className="btn-sky px-20 py-[10px] text-base font-semibold">Next</button>
                                <Cancle />
                            </div>
                        </div>
                    </Form>
                )}
        </Formik>

    );
};

export default CreatePassword;