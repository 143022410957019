import React from 'react';
import DigitalServices from '../../Molecules/Accounts/ProviderAndSupport/DigitalServices';

const ProviderAndSupport = () => {
    return (
        <>
            <DigitalServices />
        </>
    );
};

export default ProviderAndSupport;